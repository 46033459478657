/*eslint-disable */
export const apiDomain = API_URL;
export const APP_DOMAIN = API_URL;
export const APPCAST_HOST_PUBLISH_SPOT =
    typeof APPCAST_HOST_PUBLISH_SPOT_ENV !== 'undefined' ? APPCAST_HOST_PUBLISH_SPOT_ENV : null;
export const APPLE_MAPS_TEAM_ID = APPLE_MAPS_TEAM_ID_ENV;
export const APPLE_MAPS_KIT_ID = APPLE_MAPS_KIT_ID_ENV;
export const APPLE_MAPS_KIT_PK = APPLE_MAPS_KIT_PK_ENV.replace(/\\n/g, '\n');
export const AWS_ACCESS_KEY_ID = AWS_ACCESS_KEY_ID_ENV;
export const AWS_ASSET_HOST = AWS_ASSET_HOST_ENV;
export const AWS_BUCKET = AWS_BUCKET_ENV;
export const AWS_REGION = AWS_REGION_ENV;
export const AWS_SECRET_ACCESS_KEY = AWS_SECRET_ACCESS_KEY_ENV;
export const AWS_STATIC_ASSET_HOST = AWS_STATIC_ASSET_HOST_ENV;
export const COOKIE_MODE = COOKIE_MODE_KEY;
export const FACEBOOK_PIXEl_ID = typeof FACEBOOK_ID !== 'undefined' ? FACEBOOK_ID : null;
export const FB_APP_ID = APP_ID;
export const GOOGLE_ADS_CODE = GOOGLE_ADS;
export const GOOGLE_ADS_HOST_SIGN_IN =
    typeof GOOGLE_ADS_HOST_SIGN_IN_ENV !== 'undefined' ? GOOGLE_ADS_HOST_SIGN_IN_ENV : null;
export const GOOGLE_ADS_PUBLISH_SPOT =
    typeof GOOGLE_ADS_PUBLISH_SPOT_ENV !== 'undefined' ? GOOGLE_ADS_PUBLISH_SPOT_ENV : null;
export const GOOGLE_ADS_CREATE_HALF_ACRE_SPOT =
    typeof GOOGLE_ADS_CREATE_HALF_ACRE_SPOT_ENV !== 'undefined' ? GOOGLE_ADS_CREATE_HALF_ACRE_SPOT_ENV : null;
export const GOOGLE_ADS_PUBLISH_HALF_ACRE_SPOT =
    typeof GOOGLE_ADS_PUBLISH_HALF_ACRE_SPOT_ENV !== 'undefined' ? GOOGLE_ADS_PUBLISH_HALF_ACRE_SPOT_ENV : null;
export const GOOGLE_ANALYTICS_CODE =
    typeof GOOGLE_ANALYTICS_CODE_ENV !== 'undefined' ? GOOGLE_ANALYTICS_CODE_ENV : null;
export const GOOGLE_GA4_CODE = typeof GOOGLE_GA4_CODE_ENV !== 'undefined' ? GOOGLE_GA4_CODE_ENV : null;
export const TIKTOK_PIXEL_ID = typeof TIKTOK_PIXEL_ID_ENV !== 'undefined' ? TIKTOK_PIXEL_ID_ENV : null;
export const GOOGLE_MAP_API_KEY = MAP_KEY;
export const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places&language=en`;
export const GOOGLE_MAP_TIMEZONE_URL = `https://maps.googleapis.com/maps/api/timezone/json?key=${GOOGLE_MAP_API_KEY}&language=en`;
export const GOOGLE_CLIENT_ID = GOOGLE_CLIENT_ID_ENV;
export const NEXTDOOR_ADS_CODE = NEXTDOOR_ADS_CODE_ENV;
export const PRODUCTION = PROD;
export const REDDIT_PIXEL_ID = REDDIT_PIXEL_ID_ENV;
export const RUN_MODE = SNIFF_BUILD_MODE;
export const STRIPE_KEY = STRIPE_PUBLIC;
export const WITH_PLAYMATES = typeof SNIFF_DISABLE_PLAYMATES !== 'undefined' ? !SNIFF_DISABLE_PLAYMATES : true;
