import queryString from 'query-string';

function templateUrl(url, params, { part, query } = {}) {
    return (
        url
            .split('/')
            .map((item) => {
                const isParam = item[0] === ':';
                if (isParam && typeof params === 'object' && params !== null) {
                    const paramName = item.replace(/\?/g, '').substr(1);
                    return params[paramName];
                }

                return item;
            })
            .filter((item) => item !== undefined)
            .join('/') +
        (part ? `/${part}` : '') +
        (query ? `?${queryString.stringify(query)}` : '')
    );
}

function formUrl(url) {
    return (params, extra) => templateUrl(url, params, extra);
}

export const RouteFormatter = {
    addDog: formUrl('/guest_account/add-dog'),
    allLocation: formUrl('/listings/locations'),
    app: formUrl('/mobile'),
    bookingConfirmation: formUrl('/booking-confirmation/:code'),
    commuityDownload: formUrl('/communities/:id'),
    calendar: formUrl('/host_account/calendar/:id'),
    cancelReservation: formUrl('/cancel-reservation/:code'),
    customerReviews: formUrl('/customer-reviews'),
    createSpot: formUrl('/host_account/spots/:id/create'),
    community: formUrl('/host_account/community'),
    postPublishSpot: formUrl('/host_account/spots/:id/post-publish'),
    editDog: formUrl('/guest_account/dogs/:dogSlug'),
    editSpot: formUrl('/host_account/spots/:id/edit'),
    marketing: formUrl('/host_account/marketing'),
    earnings: formUrl('/host_account/earnings'),
    earningsMethod: formUrl('/host_account/earnings/method'),
    earningsHistory: formUrl('/host_account/earnings/history'),
    onboarding: formUrl('/host_account/onboarding'),
    extras: formUrl('/host_account/extras'),
    extrasAdd: formUrl('/host_account/extras/add'),
    favorites: formUrl('/favorites'),
    dogNamesSub: formUrl('/dog-names/:slug'),
    dogNames: formUrl('/dog-names'),
    dogRes: formUrl('/dog-rescues'),
    dogRescue: formUrl('/dog-rescues/united-states'),
    dogRescueSub: formUrl('/dog-rescues/:id'),
    guestAccount: formUrl('/guest_account'),
    guestConversation: formUrl('/guest_messages/:channelId/:third?'),
    guestMessages: formUrl('/guest_messages'),
    guestProfile: formUrl('/guest_account/profile'),
    memberships: formUrl('/memberships'),
    membershipsDetail: formUrl('/memberships/:id'),
    sniffpass: formUrl('/sniffpass'),
    sniffpassTrial: formUrl('/sniffpass-free-trial'),
    subscriptions: formUrl('/subscriptions'),
    subscriptionsNew: formUrl('/subscriptions/new'),
    home: formUrl('/'),
    emailVerified: formUrl('/email-verified'),
    phoneVerified: formUrl('/phone-verified'),
    hostAccount: formUrl('/host_account'),
    hostAccountEdit: formUrl('/host_account/edit'),
    hostConversation: formUrl('/host_messages/:channelId'),
    hostSpots: formUrl('/host_account/spots-list'),
    hostLanding: formUrl('/host'),
    hostLandingNone: formUrl('/host_none'),
    hostLandingSize: formUrl('/host_size'),
    hostLandingSizeFencing: formUrl('/host_size_fencing'),
    hostMessages: formUrl('/host_messages'),
    hostMemberships: formUrl('/host_account/memberships/:id'),
    hostReviews: formUrl('/host_account/reviews'),
    invite: formUrl('/invite'),
    listings: formUrl('/listings/:first?/:second?/:third?'),
    listings2: formUrl('/listings_2/:first?/:second?/:third?'),
    listingsVideo: formUrl('/listings-video/:first?/:second?/:third?'),
    waterListings: formUrl('/listings/water-parks'),
    agilityListings: formUrl('/listings/dog-agility-parks'),
    fencedListings: formUrl('/listings/fenced-parks'),
    fieldsListings: formUrl('/listings/dog-parks-fields'),
    hikingListings: formUrl('/listings/hiking-trails'),
    smallDogListings: formUrl('/listings/small-dog-friendly'),
    beachListings: formUrl('/listings/dog-beaches'),
    indoorListings: formUrl('/listings/indoor-dog-parks'),
    newSpot: formUrl('/host_account/spots/new'),
    newSpotAlerts: formUrl('/guest_account/new_spot_alerts'),
    payments: formUrl('/payments'),
    paymentsEdit: formUrl('/payments/:id'),
    paymentsNew: formUrl('/payments/new'),
    profile: formUrl('/profile/:id/:third?'),
    reservationList: formUrl('/host_account/reservation'),
    reservationDetail: formUrl('/host_account/reservations/:id'),
    reserve: formUrl('/reserve/:id'),
    review: formUrl('/reviews/:id'),
    signIn: formUrl('/users/sign_in'),
    signInHost: formUrl('/users/sign_in_host'),
    signInNew: formUrl('/sign_in'),
    signOut: formUrl('/users/sign_out'),
    signUp: formUrl('/users/sign_up'),
    signUpHost: formUrl('/users/sign_up_host'),
    signUpRef: formUrl('/users/sign_up_referral'),
    spotList: formUrl('/host_account/spots'),
    spotCompletion: formUrl('/host_account/spot_completion/:id'),
    syncGoogle: formUrl('/google_callback'),
    visitEdit: formUrl('/visit/:id/edit'),
    visitInvite: formUrl('/visit/:key/invite'),
    visitList: formUrl('/visit'),
    visitView: formUrl('/visit/:reservationId/:third?'),
    visitDetails: formUrl('/visit/:reservationId'),
    visitDetailsReport: formUrl('/visit/:reservationId/report'),
    welcome: formUrl('/welcome'),
    blog: formUrl('/blog'),
    blogAll: formUrl('/blog/all'),
    blogCategory: formUrl('/blog/:id'),
    blogPost: formUrl('/blog/:cat/:slug'),
    blogAuthor: formUrl('/blog/author/:slug'),
    blogEnrichment: formUrl('/blog/dog-enrichment/'),
    blogReactivity: formUrl('/blog/dog-reactivity/'),
    blogTraining: formUrl('/blog/dog-training/'),
    blogTrainers: formUrl('/blog/dog-trainers'),
    blogTrainersSub: formUrl('/blog/dog-trainers/:slug'),
    blogTrainersUS: formUrl('/blog/dog-trainers/united-states'),
    jobs: formUrl('https://angel.co/company/sniffspot/jobs'),
    web: {
        a5star: formUrl('/5-stars-experience'),
        about: formUrl('/about'),
        guarantee: formUrl('/damage-protection-guarantee'),
        privacy: formUrl('/privacy-policy'),
        root: formUrl('https://web.sniffspot.com'),
        terms: formUrl('/terms-of-service'),
        trust: formUrl('/trust'),
        vsParks: formUrl('/sniffspot-vs-public'),
        waiver: formUrl('/waiver-and-release'),
    },
    helpCenter: {
        bestExperience: formUrl('https://help.sniffspot.com/article/107-overview-for-providing-the-best-experience-for-guests'),
        bestListing: formUrl('https://help.sniffspot.com/article/95-overview-making-the-best-spot-listing'),
        calendar: formUrl('https://help.sniffspot.com/article/37-managing-your-calendar'),
        cancellation: formUrl('https://help.sniffspot.com/article/15-what-is-sniffspots-cancellation-policy'),
        charge: formUrl('https://help.sniffspot.com/article/32-how-much-does-sniffspot-charge'),
        community: formUrl('https://help.sniffspot.com/article/173-sniffspot-community-guidelines'),
        extras: formUrl('https://help.sniffspot.com/article/237-extras'),
        greatHost: formUrl('https://help.sniffspot.com/category/54-how-to-be-a-great-host'),
        gettingStarted: formUrl('https://help.sniffspot.com/category/58-getting-started'),
        guestFaq: formUrl('https://help.sniffspot.com/collection/5-guest-faq'),
        hostFaq: formUrl('https://help.sniffspot.com/collection/1-host-faq'),
        hostStandart: formUrl('https://help.sniffspot.com/article/93-host-standards'),
        inclusion: formUrl('https://help.sniffspot.com/article/220-sniffspot-inclusion-policy'),
        liability: formUrl('https://help.sniffspot.com/article/200-2m-host-protection-insurance'),
        memberships: formUrl('https://help.sniffspot.com/article/273-memberships'),
        membershipsGuest: formUrl('https://help.sniffspot.com/article/284-memberships-for-guests'),
        photoGuide: formUrl('https://help.sniffspot.com/article/96-spot-photography-guide'),
        requirements: formUrl('https://help.sniffspot.com/article/17-what-are-the-requirements-to-host'),
        root: formUrl('https://help.sniffspot.com'),
        sign: formUrl('https://help.sniffspot.com/article/115-making-signs-for-your-spot'),
        sniffpass: formUrl('https://help.sniffspot.com/article/355-sniffpass-for-hosts'),
        sniffpassGuest: formUrl('https://help.sniffspot.com/article/356-sniffpass-for-guests'),
        spotMarketing: formUrl('https://help.sniffspot.com/article/108-overview-for-marketing-your-spot'),
        vaccinations: formUrl('https://help.sniffspot.com/article/69-what-vaccinations-do-you-require'),
        waterSafe: formUrl('https://help.sniffspot.com/article/167-how-to-keep-your-dog-safe-around-pools-and-water'),
        pricing: formUrl('https://help.sniffspot.com/article/25-how-do-i-set-a-price-for-my-sniff-spot'),
    },
};
